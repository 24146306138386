import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import Slider from '@mui/material/Slider'
import { alpha, styled } from '@mui/material/styles'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'
import Icon from '../Icon'
import Logo from '../Logo'
import homeImg from '../../../assets/images/home.svg'
import { Spinner } from '../Spinner'
import styles from './styles.module.scss'

const SuccessSlider = styled(Slider)(({ theme }) => ({
	width:   '100%',
	padding: '9px 0 9px 0',
	color:   '#FE3F55',

	[theme.breakpoints.down('sm')]: {
		padding: '9px 0 9px 0',
	},

	'& .MuiSlider-rail':  {
		color:                          '#CACACA',
		borderRadius:                   '4px',
		[theme.breakpoints.down('sm')]: {
			height: '2px',
		},
	},
	'& .MuiSlider-track': {
		[theme.breakpoints.down('sm')]: {
			height: '2px',
		},
	},
	'& .MuiSlider-thumb': {
		width:  '24px',
		height: '24px',

		'&:hover, &.Mui-focusVisible':  {
			boxShadow: 'none',
		},
		'&.Mui-active':                 {
			boxShadow: `0px 0px 0px 14px ${alpha('#FE3F55', 0.16)}`,
		},
		[theme.breakpoints.down('sm')]: {
			borderRadius: '8px',
		},
	},
}))

export const SliderMap = ({
	onChange,
	onDone,
	value,
	result,
	isFetching,
	getTotalLots,
}) => {
	const { isLessIPadMini } = useDeviceDetect()
	const intl = useIntl()

	return (
		<div className={styles.sliderContainer}>
			<div className={styles.sliderWrapper}>
				<div className={styles.sliderHeader}>
					<div className={styles.sliderRadius}>
						<span className={styles.sliderRadiusText}>{intl.formatMessage({ id: 'lots_radius' })}</span>
						<Icon
							icon="positionSmallIcon"
							size={isLessIPadMini ? '14px' : '14px'}
							className={styles.icon}
						/>
						<span
							className={styles.sliderRadiusCount}
						>
							{`${value} ${intl.formatMessage({ id: 'measure_km' })}`}
						</span>
					</div>
					<div className={styles.count}>
						{isFetching ?
							<Spinner isFetching size={16} /> :
							result}
						<div>
							{isLessIPadMini ? <Logo size={isLessIPadMini ? '20px' : '25px'} /> :
								intl.formatMessage({ id: 'lot_count_many' }).replace('%d&nbsp;', '')}
						</div>

					</div>
				</div>
				<div className={styles.slider}>
					 {/* {!isLessIPadMini && <img src={homeImg} alt="home" />} */}
					<SuccessSlider
						min={3}
						step={1}
						max={200}
						value={typeof value === 'number' ? value : 0}
						onChange={onChange}
						onChangeCommitted={getTotalLots}
					/>
				</div>
			</div>
			<div className={styles.buttonDone}>
				<button type="button" onClick={onDone}>{intl.formatMessage({ id: 'done' })}</button>
			</div>
		</div>
	)
}
SliderMap.propTypes = {
	onChange:     PropTypes.func,
	onDone:       PropTypes.func,
	getTotalLots: PropTypes.func,
	value:        PropTypes.number,
	result:       PropTypes.number,
	isFetching:   PropTypes.bool,
}
SliderMap.defaultProps = {
	onChange:     () => null,
	onDone:       () => null,
	getTotalLots: () => null,
	value:        0,
	result:       2000,
	isFetching:   false,
}
