import * as geolib from 'geolib'
import { currencyTypes, types } from '../../constants'

export const useShowDelivery = (position, latitude, longitude, is_promo, type, distance, currency_type) => {
	const getDistance = () => {
		if (!position || !latitude || !longitude) {
			return false
		}
		return geolib.getDistance(
			{
				latitude:  position.lat,
				longitude: position.lng,
			}, {
				latitude,
				longitude,
			},
		) / 1000
	}

	return !!(((is_promo && type !== types.TYPE_PROMOTION && distance <= 0) ||
		currency_type === currencyTypes.TYPE_MONEY) &&
	Math.round(getDistance()) > 60)
}
