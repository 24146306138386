import PropTypes from 'prop-types'
import React from 'react'
import GeoSolve from '../GeoSolve/index.store'
import ItemList from '../ItemsList/index.store'
import TopBarSpacerBlock from '../TopBarSpacerBlock/index.store'
import styles from './styles.module.scss'

export const ItemBlock = ({
	items,
	onClickGetMore,
	className,
	title,
	titleLink,
	sort,
	isVisibleGeoSolve,
	setIsCloseGeo,
}) => (
	<div className={[styles.main, className].join(' ')}>
		{!isVisibleGeoSolve && <GeoSolve setIsCloseGeo={setIsCloseGeo} />}
		{title && (
			<TopBarSpacerBlock title={title} titleLink={titleLink} sort={sort} />
		)}
		<ItemList items={items} onClickGetMore={onClickGetMore} />
	</div>
)

ItemBlock.propTypes = {
	items:             PropTypes.array,
	sort:              PropTypes.number,
	title:             PropTypes.string,
	titleLink:         PropTypes.string,
	onClickGetMore:    PropTypes.func,
	setIsCloseGeo:     PropTypes.func,
	className:         PropTypes.string,
	isVisibleGeoSolve: PropTypes.bool,
}
ItemBlock.defaultProps = {
	items:             [],
	sort:              9,
	title:             '',
	titleLink:         '',
	onClickGetMore:    () => null,
	setIsCloseGeo:     () => null,
	className:         '',
	isVisibleGeoSolve: false,
}
