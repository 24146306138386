import { connect } from 'react-redux'
import {
	StateSelector,
} from 'reduxm/modules/selectors'
import { ItemList } from './index'
import { isFetching } from '../../../helpers/methods'

const mapStateToProps = state => ({
	isFetching:     isFetching(
		StateSelector.items.getMoreItems(state),
	),

})
export default connect(mapStateToProps)(ItemList)
