import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { timeToString } from '../../../helpers/timeToString'

export const Time = ({ updateItem, item, expiredAt }) => {
	const intl = useIntl()
	const [time, setTime] = useState('')
	const [secondsLeft, setSecondsLeft] = useState(0)

	useEffect(() => setTime(timeToString(secondsLeft)), [secondsLeft])

	useEffect(() => {
		const timestamp = Math.round(Date.now() / 1000)
		const timeLeft = expiredAt - timestamp
		setSecondsLeft(timeLeft)
	}, [item, expiredAt])

	useEffect(() => {
		let timer
		let delay

		if (secondsLeft > 0) {
			timer = setInterval(() => setSecondsLeft(prevState => prevState - 1), 1000)
		} else if (!item.updatedStatus) {
			setTime(intl.formatMessage({ id: 'waiting_karma' }))

			const searchQuery = DataProvider.buildQuery()
				.setQueryParam('id', item.id)

			delay = setTimeout(() => updateItem(searchQuery), 10000)
		} else {
			setTime(item.updatedStatus)
		}

		return () => {
			clearTimeout(delay)
			clearInterval(timer)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secondsLeft, updateItem, item])

	return (
		<>
			{time}
		</>
	)
}

Time.propTypes = {
	item:       PropTypes.object,
	updateItem: PropTypes.func,
	expiredAt:  PropTypes.number,

}
Time.defaultProps = {
	item:       {},
	updateItem: () => null,
	expiredAt:  0,
}
