import { connect } from 'react-redux'
import { setQueryParams, setSearchString } from '../../../reduxm/redux-saga/modules/base/actionsCreators'

import { TopBarSpacerBlock } from './index'

const mapStateToProps = state => ({
	queryParams:        state.base.queryParams,
})
const mapDispatchToProps = {
	setQueryParams,
	setSearchString,
}
export default connect(mapStateToProps, mapDispatchToProps)(TopBarSpacerBlock)
