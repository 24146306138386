import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import { useDragDetection } from '../useDragDetection'
import styles from './styles.module.scss'

const settings = {
	variableWidth:  true,
	dots:           false,
	speed:          500,
	slidesToScroll: 6,
	arrows:         false,
	infinite:       false,
	initialSlide:   0,
	responsive:     [
		{
			breakpoint: 700,
			settings:   {
				slidesToScroll: 5,
			},
		},
		{
			breakpoint: 550,
			settings:   {
				slidesToScroll: 1,
			},
		},
	],

}

export const ChildrenCategories = ({ childrenCategories, queryParams, setQueryParams, openFilter }) => {
	const { dragging, handleMouseDown } = useDragDetection()

	const onSelectChildrenCategories = (event, elem) => {
		if (dragging) {
			event.preventDefault()
			return
		}
		const target = event.target.id

		const id = parseInt(target, 10)

		setQueryParams({
			...queryParams,
			filter: (queryParams?.filter === id) ? 0 : id,
		})
		openFilter(elem)
	}

	return (
		<div className={childrenCategories.length ? styles.sliderWrapper : ''}>
			<Slider {...settings}>
				{childrenCategories.map(item => (
					<button
						type="button"
						key={item.id}
						id={item.id}
						className={styles.button}
						onMouseDownCapture={handleMouseDown}
						onClick={e => onSelectChildrenCategories(e, item)}
					>
						{item.title}
					</button>
				))}
			</Slider>
		</div>)
}

ChildrenCategories.propTypes = {
	setQueryParams:     PropTypes.func,
	queryParams:        PropTypes.object,
	childrenCategories: PropTypes.array,
	setSearchString:    PropTypes.func,
	openFilter:         PropTypes.func,
	searchString:       PropTypes.string,

}
ChildrenCategories.defaultProps = {
	setQueryParams:     () => null,
	queryParams:        {},
	childrenCategories: [],
	setSearchString:    () => null,
	openFilter:         () => null,
	searchString:       '',
}
