import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DispatchSelector, StateSelector } from 'reduxm/modules/selectors'
import {
	setQueryLocation,
	setQueryDistance,
	setQueryCity,
} from 'reduxm/redux-saga/modules/queryParams/actionsCreators'
import { setQueryParams, setSearchString } from 'reduxm/redux-saga/modules/base/actionsCreators'

import { MapButton } from './index'

const mapStateToProps = state => ({
	stateItemsQuantity:     StateSelector.items.getItemsQuantity(state),
	userState:              StateSelector.user.getUser(state),
	geoState:               StateSelector.project.getGeoState(state),
	queryParams:            state.queryParams,
	intlState:              state.intl,
	cityState:              StateSelector.cities.getCity(state),
})
const mapDispatchToProps = {
	getItemsQuantity:       DispatchSelector.items.getItemsQuantity,
	getCity:                DispatchSelector.cities.getCity,
	setQueryLocation,
	setQueryDistance,
	setQueryCity,
	setQueryParams,
	setSearchString,
	getUserUpDate:          DispatchSelector.user.getUserUpDate,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapButton))
