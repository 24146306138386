import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * Item model class
 */
class Item extends BaseModel {
	/**
	 * Create Service instance
	 *
	 * @param {Object} object
	 *
	 * @returns {Item}
	 */
	static create(object) {
		return super.create(object)
	}

	/**
	 * Create list models
	 *
	 * @returns {Array<BaseModel>}
	 * @param objects
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Create list models
	 *
	 * @returns {Array<Object>}
	 * @param objects: Array<Object>
	 * @param callback: function(BaseModel, number): undefined
	 */
	static map(objects, callback) {
		return BaseModel.getArrayObjects(objects).map((object, index) => {
			const modelInstance = this.create(object)
			return callback(modelInstance, index)
		})
	}

	/**
	 * Create list models
	 *
	 * @returns {Array<Object>}
	 * @param object: <Object>
	 */
	static getArrayObjects(object) {
		return Array.isArray(object && object.result && object.result.list) &&
			object.result.list || Array.isArray(object && object.list) &&
			object.list || Array.isArray(object && object.result) &&
			object.result || Array.isArray(object) && object || []
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getAlias = () => this.model.alias

	/**
	 * Get description
	 *
	 * @returns {string}
	 */
	getDescription = () => this.model.description || ''

	/**
	 * Get status
	 *
	 * @returns {number}
	 */
	getStatus = () => this.model.status

	/**
	 * Get type
	 *
	 * @returns {string}
	 */
	getType = () => this.model.type

	/**
	 * Get media
	 *
	 * @returns []
	 */
	getMedia = () => this.model.media || []

	/**
	 * Get karma
	 *
	 * @returns {number}
	 */
	getKarma = () => this.model.karma

	/**
	 * Get liked
	 *
	 * @returns {boolean}
	 */
	getLiked = () => this.model.liked

	/**
	 * Get User
	 *
	 * @returns {JSON}
	 */
	getUser = () => this.model.user

	/**
	 * Get likes
	 *
	 * @return {number}
	 */
	getLikes = () => this.model.likes

	/**
	 * Get start
	 *
	 * @return {number}
	 */
	getStart = () => this.model.start

	/**
	 * Get lat
	 *
	 * @return {number}
	 */
	getLat = () => this.model.lat

	/**
	 * Get lon
	 *
	 * @return {number}
	 */
	getLon = () => this.model.lon

	/**
	 * Get lifetime
	 *
	 * @return {number}
	 */
	getLifetime = () => this.model.lifetime

	/**
	 * Get last bet
	 *
	 * @return {JSON}
	 */
	getLastBet = () => this.model.last_bet

	/**
	 * Get chat id
	 *
	 * @return {number}
	 */
	getChatId = () => this.model.chat_id

	/**
	 * Get message
	 *
	 * @return {string}
	 */
	getMessage = () => this.model.has_message

	/**
	 * Get distance
	 *
	 * @return {number}
	 */
	getDistance = () => this.model.distance

	/**
	 * Get PromoParams
	 *
	 * @return {array}
	 */
	getPromoParams = () => this.model.promo_params

	/**
	 * Get PromoParams
	 *
	 * @return {array}
	 */
	getIsPromo = () => this.model.is_promo

	/**
	 * Get Tags
	 *
	 * @return {array}
	 */
	getTags = () => this.model.tags || []

	/**
	 * Get isFinish
	 *
	 * @return {boolean}
	 */
	getIsFinish = () => this.model.is_finish

	/**
	 * Get category_id
	 *
	 * @return {number}
	 */
	getCategoryId = () => this.model.category_id

	/**
	*
	*
	 */
	getExpiredAt = () => this.model.expired_at

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}

	/**
	 * Get buy now price
	 *
	 * @return {number}
	 */
	getBuyNowPrice = () => this.model.buy_now_price

	/**
	 * Get currency
	 *
	 * @return {object}
	 */
	getCurrency = () => this.model.currency

	/**
	 * Get currency type
	 *
	 * @return {string}
	 */
	getCurrencyType = () => this.model.currency_type

	/**
	 * Get next bid
	 *
	 * @return {number}
	 */
	getNextBid = () => this.model.next_bid
}

export default Item
