import { connect } from 'react-redux'
import {
	DispatchSelector,
} from 'reduxm/modules/selectors'
import { Time } from './index'

const mapDispatchToProps = {
	updateItem:     DispatchSelector.items.updateItem,
}
export default connect(null, mapDispatchToProps)(Time)
