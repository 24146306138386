import { getCookie, setCookie } from '@kakadu-dev/base-frontend-helpers/helpers/Cookie'
import { SvgIcon } from '@material-ui/core'
import { LocationOn } from '@material-ui/icons/'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Geocode from 'react-geocode'
import { useIntl } from 'react-intl'
import { User } from '../../../models'
import Icon from '../Icon'
import styles from './styles.module.scss'

export const GeoSolve = ({
	setGeoState,
	geoState,
	setQueryParams,
	userState,
	queryParams,
	getUserUpDate,
	setIsCloseGeo,
}) => {
	const intl = useIntl()
	Geocode.setApiKey('AIzaSyDxWiZxY3Z9Ht8NwoOtV_a_wTCdvOQ2TUE')
	Geocode.setLanguage(intl.locale)

	// eslint-disable-next-line no-unused-vars
	const [isClose, setIsClose] = useState(true)
	const { result: isGeo } = geoState ?? false
	const [status, setStatus] = useState(null)
	const authLogined = new User(userState).isExist()

	const getLocation = () => {
		setGeoState(true)

		if (!navigator.geolocation) {
			setStatus('Geolocation is not supported by your browser')
		} else {
			setStatus('Locating...')
			navigator.geolocation.getCurrentPosition(position => {
				setStatus(null)

				Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
					.then(
						response => {
							let city
							let country_code
							// eslint-disable-next-line no-plusplus
							for (let i = 0; i < response.results[0].address_components.length; i++) {
								// eslint-disable-next-line no-plusplus
								for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
									// eslint-disable-next-line default-case
									switch (response.results[0].address_components[i].types[j]) {
										case 'locality':
											city = response.results[0].address_components[i].long_name
											break
										case 'country':
											country_code = response.results[0].address_components[i].short_name
											break
									}
								}
							}
							setQueryParams({
								...queryParams,
								lat:      response.results[0].geometry.location.lat,
								lon:      response.results[0].geometry.location.lng,
								city,
								country_code,
								distance: 10,
							})

							if (authLogined) {
								const searchQuery = {
									distance: 10,
									geo:      {
										lat: response.results[0].geometry.location.lat,
										lon: response.results[0].geometry.location.lng,
										city,
										country_code,
									},

								}
								getUserUpDate(searchQuery)
							}
						},
						error => {
							// eslint-disable-next-line no-console
							console.error(error)
						},
					)
			}, positionError => {
				if (positionError === 1) {
					setStatus('Вы решили не предоставлять данные о своем местоположении, ' +
						'но это не проблема. Мы больше не будем запрашивать их у вас.')
				} else if (positionError === 2) {
					setStatus('Проблемы с сетью или нельзя связаться со службой определения ' +
						'местоположения по каким-либо другим причинам.')
				} else if (positionError === 3) {
					setStatus('He удалось определить местоположение ' +
						'в течение установленного времени. ')
				} else {
					setStatus('Загадочная ошибка')
				}
			}, {
				enableHighAccuracy: true,
				timeout:            10000,
				maximumAge:         60000,
			})
		}
	}

	const closeGeoSolve = () => {
		setCookie('hidden_geo_solve', true, true)
		setIsCloseGeo(false)
		setIsClose(false)
	}

	return (!isGeo && !getCookie('hidden_geo_solve') ?
		<div className={styles.wrapper}>
			{status ? <span>{status}</span> : null}
			<div className={styles.leftBlock}>
				{/* <SvgIcon component={LocationOn} className={styles.icon} /> */}
				<div className={styles.textBlock}>
					<span className={styles.tittle}>{intl.formatMessage({ id: 'geo_solve_title' })}</span>
					<span className={styles.question}>{intl.formatMessage({ id: 'geo_solve_question' })}</span>
				</div>
			</div>
			<div className={styles.buttonBlock}>
				<button type="button" onClick={getLocation}>
					{intl.formatMessage({ id: 'geo_allow_button' })}
				</button>
				<div style={{
					width:  '20px',
					height: '20px',
				}}
				>
					<Icon icon="closeIcon" color="rgba(43, 43, 43, 0.35)" size="20" onClick={closeGeoSolve} />
				</div>

			</div>
		</div> :
		null
	)
}

GeoSolve.propTypes = {
	geoState:       PropTypes.object,
	userState:      PropTypes.object,
	queryParams:    PropTypes.object,
	setGeoState:    PropTypes.func,
	setQueryParams: PropTypes.func,
	getUserUpDate:  PropTypes.func,
	setIsCloseGeo:  PropTypes.func,
}
GeoSolve.defaultProps = {
	geoState:       {},
	queryParams:    {},
	userState:      {},
	setGeoState:    () => null,
	getUserUpDate:  () => null,
	setQueryParams: () => null,
	setIsCloseGeo:  () => null,
}
