function rtrim(str, charlist) {	// Strip whitespace (or other characters) from the end of a string
	// eslint-disable-next-line no-underscore-dangle
	const _charlist = !charlist ? ' s\xA0' : charlist.replace(/([[\]().?/*{}+$^:])/g, '$1')
	const re = new RegExp(`[${_charlist}]+$`, 'g')
	return str.replace(re, '')
}
// ToDo fix shortenDescription
export const shortenDescription = (description, size) => {
	if (description.length > size) {
		const maxString = description.substr(0, size)
		const rightTrim = rtrim(maxString, '!.,-')
		const lastIndex = rightTrim.lastIndexOf(' ')
		if (lastIndex > -1) return rightTrim.substr(0, lastIndex)
		return rightTrim.substr(0, size)
	}
	return description
}
