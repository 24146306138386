import { SvgIcon } from '@material-ui/core'
import { ArrowBack, Search } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useDeviceDetect } from 'helpers/useDeviceDetect'
import styles from './styles.module.scss'

export const AutocompleteMap = ({
	onPlaceSelected,
	isOpenSearch,
	setIsOpenSearch,
}) => {
	const { isLessIPadMini } = useDeviceDetect()
	return (
		<div className={styles.autocompleteWrapper}>
			<div className={styles.autocomplete}>
				<div className={styles.search}>
					{isOpenSearch ?
						<SvgIcon
							component={ArrowBack}
							className={styles.searchIcon}
							onClick={() => setIsOpenSearch(false)}
						/> :
						<SvgIcon component={Search} className={styles.searchIcon} />}
				</div>
				<Autocomplete
					className={[styles.input, isLessIPadMini ? styles.caret : 0].join(' ')}
					apiKey="AIzaSyDxWiZxY3Z9Ht8NwoOtV_a_wTCdvOQ2TUE"
					onPlaceSelected={onPlaceSelected}
					id="autocomplete"
					options={{
						types: ['geocode', 'establishment'],
					}}
					placeholder={isOpenSearch ? 'Город...' : ''}
				/>
			</div>
		</div>
	)
}

AutocompleteMap.propTypes = {
	onPlaceSelected: PropTypes.func,
	setIsOpenSearch: PropTypes.func,
	isOpenSearch:    PropTypes.bool,
}

AutocompleteMap.defaultProps = {
	onPlaceSelected: () => null,
	setIsOpenSearch: () => null,
	isOpenSearch:    false,
}
