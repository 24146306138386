import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { LikeButton } from './index'

const mapStateToProps = state => ({
	userState: StateSelector.user.getUser(state),
})

const mapDispatchToProps = {
	likeItem: DispatchSelector.items.likeItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeButton)
