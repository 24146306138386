import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useIntl } from 'react-intl'
import ItemCard from '../ItemCard/index.store'
import styles from './styles.module.scss'
import { Spinner } from '../Spinner'

export const ItemList = ({ items, isObserve, isFetching, onClickGetMore }) => {
	const intl = useIntl()
	const showLots = () => items.map(item => {
		switch (item.type) {
			case 'lot': return <ItemCard key={item.data.id} _item={item.data} isObserve={isObserve} />
			default: return <ItemCard key={item.id} _item={item} isObserve={isObserve} />
		}
	})

	return (
		<div className={styles.listWrapper}>
			{items?.length > 0 &&
			<InfiniteScroll
				style={{ overflow: 'hidden' }}
				dataLength={items && items?.length}
				next={onClickGetMore}
				hasMore
				loader={items?.length && <Spinner isFetching={isFetching} />}
				endMessage={
					<p style={{ textAlign: 'center' }}>
						<b>Вау! Вы все посмотрели</b>
					</p>
				}
			>
				<div className={styles.itemsWrapper}>
					{items?.length && showLots()}
				</div>
			</InfiniteScroll>}
			<div className={styles.footer}>
				{(!items?.length && intl.formatMessage({ id: 'empty_simple' }))}
			</div>
		</div>
	)
}

ItemList.propTypes = {
	items:          PropTypes.array,
	isObserve:      PropTypes.bool,
	isFetching:     PropTypes.bool,
	onClickGetMore: PropTypes.func,
}
ItemList.defaultProps = {
	items:          [],
	isObserve:      false,
	isFetching:     false,
	onClickGetMore: () => null,
}
