import React from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

export const About = () => {
	const intl = useIntl()
	return (
		<div className={styles.wrapper}>
			<span className={styles.tittle}>
				{intl.formatMessage({ id: 'about_app_title' })}
			</span>
			<div
				className={styles.textContainer}
				dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'about_app' }) }}
			/>
		</div>
	)
}
