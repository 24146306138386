import { CategoriesFiltered } from 'helpers/CategoriesFiltered'
import {
	StateSelector,
	DispatchSelector,
} from 'reduxm/modules/selectors'
import {
	setQueryParams,
	setSearchString,
} from 'reduxm/redux-saga/modules/base/actionsCreators'
import { setQuerySorting } from 'reduxm/redux-saga/modules/queryParams/actionsCreators'

const mapStateToProps = state => ({
	stateItemsCount:    StateSelector.items.getItemsCount(state),
	stateCategories:    StateSelector.categories.getCategories(state),
	queryParams:        state.queryParams,
	categories:         CategoriesFiltered.wrapped(StateSelector.categories.getCategories(state), state.intl)
})

const mapDispatchToProps = {
	setQuerySorting,
	setQueryParams,
	setSearchString,
	getItemsCount:   DispatchSelector.items.getItemsCount,
}

export {
	mapStateToProps,
	mapDispatchToProps
}
