import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { Link } from 'react-router-dom'
import { FacebookShareButton, VKShareButton } from 'react-share'
import styles from './styles.module.scss'
import LogoImage from '../../../../assets/images/logo64x64.png'
import VkImage from '../../../../assets/images/VK.svg'
import FbImage from '../../../../assets/images/Facebook.svg'
import GoogleImage from '../../../../assets/images/Google.svg'
import Icon from '../../Icon'
import { User } from '../../../../models'
import Item from '../../../../models/item/Item'
import { Spinner } from '../../Spinner'

const domain = process.env.REACT_APP_DOMAIN

export const ShareButton = ({ className, item, stateUser, stateDeepLink, createDeepLink, isFetching }) => {
	const user = User.create(stateUser)
	const thisItem = useMemo(() => Item.create(item), [item])

	const [isActive, setIsActive] = useState(false)
	const [depLink, setDepLink] = useState('')

	useEffect(() => {
		const { url } = stateDeepLink?.result ?? {}
		if (url) {
			setDepLink(url)
		} else {
			setDepLink('ошибочка')
		}
	}, [stateDeepLink])

	const copyTextRef = useRef(null)
	const onClose = useCallback(() => setIsActive(false), [])
	const onCopy = useCallback(() => {
		copyTextRef.current.select()
		document.execCommand('copy')
	}, [])

	const clickShare = () => {
		setIsActive(true)
		const referrer = user.isExist() ? `&referrer=${user.getReferralCode()}` : ''
		const link = `${domain}/?source=share_lot${referrer}&lot_id=${thisItem.getId()}`
		const searchQuery = DataProvider.buildQuery()
		searchQuery.addBody({
			link,
			image:   thisItem.getMedia()[0].url,
			message: thisItem.getDescription(),
		})
		createDeepLink(searchQuery)
	}

	return (
		<>
			<button
				type="button"
				onClick={clickShare}
				className={`${styles.shareButton} ${className}`}
			>
				<Icon icon="shareIcon" size="30px" color="rgba(43, 43, 43, 0.8)" />
			</button>
			<Dialog open={isActive} onClose={onClose}>
				<div className={styles.wrapper}>
					<div className={styles.buttonClose}>
						<button type="button" onClick={onClose}>
							<Icon icon="closeIcon" size="24px" color="rgba(43, 43, 43, 0.6)" />
						</button>
					</div>
					<div className={styles.logoImage}>
						<img src={LogoImage} alt="ОТДАМ ДАРОМ" />
					</div>
					<div className={styles.logoText}>ОТДАМ ДАРОМ</div>
					<div className={styles.title}>
						<p>Поделись ссылкой на лот</p>
					</div>
					<div className={styles.link}>
						{
							isFetching ? (
								<>
									<input ref={copyTextRef} readOnly value="генерируем ссылку" />
									<Spinner isFetching size={16} />
								</>) :
								(
									<>
										<input ref={copyTextRef} readOnly value={depLink} />
										<button type="button" onClick={onCopy}>COPY</button>
									</>
								)
						}

					</div>
					<div className={styles.buttonBlock}>
						<VKShareButton
							url={depLink}
							title={thisItem.getDescription()}
							image={thisItem.getMedia()?.length ? thisItem.getMedia()[0].url : ''}
						>
							<div className={[styles.buttonItem, styles.buttonVK].join(' ')}>
								<img src={VkImage} alt="Поделиться через VK" />
								<span>Поделиться через VK</span>
							</div>
						</VKShareButton>
						<FacebookShareButton
							url={depLink}
						>
							<div className={[styles.buttonItem, styles.buttonFB].join(' ')}>
								<img src={FbImage} alt="Поделиться через Facebook" />
								<span>Поделиться через Facebook</span>
							</div>
						</FacebookShareButton>

						<a
							href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=someone@gmail.com"
							target="_blank"
							rel="noopener noreferrer"
							className={[styles.buttonItem, styles.buttonGoogle].join(' ')}
						>
							<img src={GoogleImage} alt="Поделиться через Google" />
							<span>Поделиться через Google</span>
						</a>

					</div>
					<div className={styles.footer}>
						<Link to="/privacy">Privacy </Link>
						<span> & </span>
						<Link to="/terms"> Terms of Use</Link>
					</div>
				</div>
			</Dialog>
		</>
	)
}

ShareButton.propTypes = {
	className:      PropTypes.string,
	item:           PropTypes.object,
	stateUser:      PropTypes.object,
	stateDeepLink:  PropTypes.object,
	createDeepLink: PropTypes.func,
	isFetching:     PropTypes.bool,
}
ShareButton.defaultProps = {
	className:      '',
	item:           {},
	stateUser:      {},
	stateDeepLink:  {},
	createDeepLink: () => null,
	isFetching:     false,
}
