import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
} from 'react'
import { useIntl } from 'react-intl'
import {
	useHistory,
	useParams,
} from 'react-router-dom'
import { GeoService } from 'services/GeoService'
import { toQueryParams } from 'helpers/methods'
import { useDeviceDetect } from 'helpers/useDeviceDetect'
import ic_search from 'assets/images/ic_search.svg'
import { AutocompleteMap } from '../../AutocompleteMap'
import { GoogleMap } from '../../GoogleMap'
import Icon from '../../Icon'
import { SliderMap } from '../../Slider'
import styles from './styles.module.scss'

export const MapButton = ({
	stateItemsQuantity,
	setQueryLocation,
	setQueryDistance,
	setQueryCity,
	geoState,
	queryParams,
	getItemsQuantity,
	setSearchString,
	intlState,
	getCity,
	cityState,
}) => {
	const intl = useIntl()
	const history = useHistory()

	const { isLessIPadMini } = useDeviceDetect()

	const { loc, qCity, qDistance } = useMemo(() => {
		const { location: { lat, lon }, city, distance } = queryParams
		return { loc: { lat, lng: lon }, qCity: city, qDistance: distance }
	}, [queryParams])

	const isGeo = geoState?.result ?? false

	const [isActive, setIsActive] = useState(false)
	const [location, setLocation] = useState()
	const [distance, setDistance] = useState(qDistance)
	const [isOpenSearch, setIsOpenSearch] = useState(false)

	const params = useParams()

	const { category: urlCategory = '', type: urlType = '' } = useMemo(() => params, [params])

	// console.log('location', location)

	useEffect(() => {
		if (isOpenSearch) {
			document.getElementById('autocomplete')
				.focus()
		}
	}, [isOpenSearch])

	const onClose = useCallback(() => {
		setIsActive(false)
		setDistance(qDistance)
		setLocation(loc)
	}, [qDistance, loc])

	const onPlaceSelected = useCallback(async (place) => {
		const mapStyle = document.querySelector('.gm-style')
		if (mapStyle) {
			mapStyle.style.marginTop = '0px'
		}

		setLocation({
			lat: place.geometry.location.lat(),
			lng: place.geometry.location.lng(),
		})
	}, [])

	const onDone = useCallback(async () => {
		const searchQuery = DataProvider.buildQuery()
			.setQueryParam('lat', location?.lat)
			.setQueryParam('lon', location?.lng)
			.setSuccessCallback(({ result: { name, alias} }) => {
				setQueryCity({ name, alias })
				setQueryLocation({ lat: location.lat, lon: location.lng })
				setQueryDistance(distance)
				setIsActive(false)
				setIsOpenSearch(false)
				setSearchString('')

				const url = `/${alias}` + `${urlCategory && `/${urlCategory}`}` + `${urlType && `/${urlType}`}`
				history.push(url)
			})

		getCity(searchQuery)
	}, [
		distance,
		setQueryLocation,
		setQueryDistance,
		setQueryCity,
		setSearchString,
		location?.lat,
		location?.lng,
		history,
	])

	const getItemsTotal = useCallback(queryLocation => {
		const searchQuery = DataProvider.buildQuery()
			.cacheResponse(3600, true)


		toQueryParams(searchQuery, queryLocation)
		getItemsQuantity(searchQuery)
	}, [getItemsQuantity])

	const handleDistance = useCallback((e, newValue) => {
		setDistance(newValue)
	},[])

	const getTotalLots = useCallback(() => {
		getItemsTotal({
			distance,
			lat:      location.lat,
			lon:      location.lng,
		})

	}, [getItemsTotal, location, distance])

	return (
		<>
			<button className={styles.buttonsMap} type="button" onClick={() => setIsActive(true)}>
				<div>
					<p>{`${qCity?.name} - ${distance} ${intl.formatMessage({ id: 'measure_km' })}`}</p>
					<Icon icon="arrowDownIcon" size="32px" color="rgba(43, 43, 43, 0.36)" />
				</div>
			</button>
			<Dialog
				open={isActive}
				onClose={onClose}
				maxWidth="md"
				fullScreen={isLessIPadMini}
				className={styles.dialog}
			>
				<div className={styles.wrapper}>
					<div className={[styles.wrapperSearch, isOpenSearch ? styles.wrapperPosition : ''].join(' ')}>
						{isOpenSearch ?
							<AutocompleteMap
								onPlaceSelected={onPlaceSelected}
								isOpenSearch={isOpenSearch}
								setIsOpenSearch={setIsOpenSearch}
							/> :
							<div className={styles.headerWrapper}>
								<div className={styles.header}>
									<button type="button" onClick={onClose} className={styles.buttonClose}>
										<Icon
											icon="closeIcon"
											size={isLessIPadMini ? '26px' : '26px'}
											color="rgba(43, 43, 43, 0.8)"
										/>
									</button>
									<div className={styles.title}>{intl.formatMessage({ id: 'lots_location' })}</div>
								</div>
								{isLessIPadMini &&
								<div className={styles.search} onClick={() => setIsOpenSearch(true)} role="none">
									 <img
										src={ic_search}
										className={styles.searchIcon}
										alt="search"
									 />
								</div>}
							</div>}
					</div>
					<div className={styles.mainContainer}>
						<GoogleMap
							positions={loc}
							setLocation={(e) => {
								setLocation(e)
							}}
							distance={distance}
							getItemsTotal={getItemsTotal}
							isGeo={isGeo}
							intlState={intlState}
						/>
						{!isLessIPadMini && <AutocompleteMap onPlaceSelected={onPlaceSelected} />}
						<SliderMap
							onChange={handleDistance}
							onDone={onDone}
							value={distance}
							isFetching={stateItemsQuantity?.fetching}
							result={stateItemsQuantity.result?.count}
							getTotalLots={getTotalLots}
						/>
					</div>
				</div>
			</Dialog>
		</>
	)
}
MapButton.propTypes = {
	stateItemsQuantity: PropTypes.object,
	queryParams:        PropTypes.object,
	setQueryLocation:   PropTypes.func,
	setQueryDistance:   PropTypes.func,
	setQueryCity:       PropTypes.func,
	geoState:           PropTypes.object,
	intlState:          PropTypes.object,
	getItemsQuantity:   PropTypes.func,
	setSearchString:    PropTypes.func,
	getCity:            PropTypes.func,
	cityState:          PropTypes.object,
}
MapButton.defaultProps = {
	stateItemsQuantity: {},
	queryParams:        {},
	setQueryLocation:   () => null,
	setQueryDistance:   () => null,
	setQueryCity:       () => null,
	geoState:           {},
	intlState:          {},
	getItemsQuantity:   () => null,
	setSearchString:    () => null,
	getCity:            () => null,
	cityState:          () => null,
}
