import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import { currencyTypes, promoStatuses, statuses, types } from 'constants'
import { shortenDescription } from 'helpers/shortenDescription'
import { useShowDelivery } from 'helpers/useShowDelivery/useSowDelivery'
import brokenImage from '../../../assets/images/corrupted-image.svg'
import delivery from '../../../assets/images/delivery.svg'
import { User } from '../../../models'
import { ContactLessDialog } from '../ContactLessDialog'
import { DownloadAppButton, textString } from '../DownloadAppButton'
import Time from '../Time/index.store'
import LikeButton from './LikeButton/index.store'
import { ShareButton } from './ShareButton'
import styles from './styles.module.scss'

export const ItemCard = React.memo(({
	_item,
	isObserve,
	createDeepLink,
	stateUser,
	stateDeepLink,
	isFetching,
	className,
	stateCurrentUserGeo,
	scrollIntoView,
}) => {
	const isShowDelivery = useShowDelivery(
		stateCurrentUserGeo.result,
		_item.lat, _item.lon,
		_item.is_promo,
		_item.type,
		_item.distance,
		_item.currency_type,
	)
	const intl = useIntl()
	// const { isMobile } = useDeviceDetect()
	const linkTo = useMemo(() =>
		({ pathname: (_item.alias ? _item.alias : `/undefined/undefined/undefined/${_item.id}`) }), [_item])
	const stayLot = _item?.promo_params?.count - _item?.promo_params?.purchased_count
	const statusLot = _item?.promo_params?.promotion_status
	const isLotMoney = _item.currency_type === currencyTypes.TYPE_MONEY && _item.type === types.TYPE_FIXED
	const isLotMoneyAuction = _item.currency_type === currencyTypes.TYPE_MONEY && _item.type === types.TYPE_AUCTION
	const isContactLessLot = _item.meeting_time === statuses.STATUS_FINISH_PARTICIPANT &&
		!_item?.promo_params &&
		_item?.currency_type === currencyTypes.TYPE_KARMA
	const isPromoActiveAgain = (statusLot === promoStatuses.STATUS_PROMO_USED ||
			statusLot === promoStatuses.STATUS_PROMO_EXPIRED) &&
		_item?.status === statuses.STATUS_IDLE
	const user = User.create(stateUser)

	const getInfo = item => {
		if (item.category_id === 17) {
			return (<div className={styles.givenLot}>{intl.formatMessage({ id: 'story_label' })}</div>)
		}
		if (item.status === statuses.STATUS_CLOSED) {
			return (
				<div className={styles.givenLot}>{intl.formatMessage({ id: 'took' })}</div>
			)
		}

		const getPromoStatus = status => {
			switch (status) {
				case promoStatuses.STATUS_PROMO_ACTIVE:
					return (
						<div>
							<Carousel
								showArrows={false}
								showThumbs={false}
								showStatus={false}
								showIndicators={false}
								infiniteLoop
								autoPlay
								interval="4000"
								transitionTime="800"
								axis="vertical"

							>
								<div className={styles.carouselItem}>
									{intl.formatMessage({ id: 'use_promocode' })}
								</div>
								<div className={styles.carouselItem}>
									<Time item={_item} expiredAt={_item?.promo_params.promotion_expired_at} />
								</div>
							</Carousel>
						</div>

					)
				case promoStatuses.STATUS_PROMO_EXPIRED || isPromoActiveAgain:
					return (
						<div>
							{intl.formatMessage({ id: 'promotion_promocode_expired' })}
						</div>
					)
				default:
					return (
						<div>
							{`${intl.formatMessage({ id: 'bonus_count_format' })
								.replace('%1$d', `${stayLot || 0}`)}`
								.replace('%2$d', `${_item?.promo_params?.count || 0}`)}
						</div>
					)
			}
		}

		if (_item.promo_params) {
			return (
				<div className={styles.itemPromo}>
					<div className={[styles.promo,
						statusLot === promoStatuses.STATUS_PROMO_EXPIRED && !isPromoActiveAgain ?
							styles.promoExpired : ''].join(' ')}
					>
						{getPromoStatus(statusLot)}
					</div>
					{_item?.promo_params?.promotion_label ?
						<div className={styles.promoPercent}>
							{_item?.promo_params?.promotion_label}
						</div> :
						null}
				</div>
			)
		}

		return (
			<div className={styles.itemTimer}>
				<div className={styles.timer}>
					<Time item={_item} expiredAt={_item.expired_at} />
				</div>
				{(isLotMoney || isLotMoneyAuction) &&
				<div className={styles.currencyInfo}>
					{`${intl.formatMessage({ id: 'buy_now' })} ${_item?.currency?.prefix}\u200A${_item?.buy_now_price}`}
				</div>}
			</div>
		)
	}

	const setAmount = () => {
		if (isLotMoney) {
			return (
				`${_item?.currency?.prefix}\u200A${_item?.buy_now_price}`
			)
		}

		if (isLotMoneyAuction) {
			return (
				`${_item?.currency?.prefix}\u200A${_item?.last_bet.amount}`
			)
		}

		return (
			`${!_item.karma ? '0' : _item.karma} ${intl.formatMessage({ id: 'karma' })}`
		)
	}

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			{isContactLessLot &&
			<ContactLessDialog scrollIntoView={scrollIntoView} />}
			<Link
				className={styles.imageBlock}
				to={linkTo}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					loading="lazy"
					src={_item.media.length ?
						_item.media[0].url :
						brokenImage}
					alt=""
					onError={e => e.target.src = brokenImage}
				/>
				{getInfo(_item)}
				<span>
					{shortenDescription(_item.description, 45)}
					...
				</span>
			</Link>
			<div className={styles.bottomPanel}>
				<div className={styles.cost}>
					{setAmount()}
					{isShowDelivery && <img src={delivery} alt="delivery" />}
				</div>
				<div className={styles.social}>
					<ShareButton
						item={_item}
						stateUser={stateUser}
						stateDeepLink={stateDeepLink}
						createDeepLink={createDeepLink}
						isFetching={isFetching}
					/>
					<LikeButton liked={_item.liked} itemId={_item.id} />
				</div>
			</div>
			{isObserve && (
				<div className={styles.downloadButtonContainer}>
					<DownloadAppButton
						obj={textString.pickUp}
						className={styles.downloadButton}
						promo={_item?.promo_params}
						status={_item.status}
						isLotMoney={isLotMoney}
						isLotMoneyAuction={isLotMoneyAuction}
						currencyPrefix={_item?.currency && _item?.currency?.prefix}
						nextBid={_item?.next_bid}
						buyNowPrice={_item.buy_now_price}
						isObserve={isObserve}
						isUserLot={user.getId() === _item.user.id}
						lotImg={_item.media[0].url}
					/>
				</div>
			)}
		</div>
	)
})

ItemCard.propTypes = {
	_item:               PropTypes.object,
	isObserve:           PropTypes.bool,
	stateUser:           PropTypes.object,
	className:           PropTypes.string,
	stateDeepLink:       PropTypes.object,
	stateCurrentUserGeo: PropTypes.object,
	createDeepLink:      PropTypes.func,
	scrollIntoView:      PropTypes.func,
	isFetching:          PropTypes.bool,
}
ItemCard.defaultProps = {
	_item:               {},
	isObserve:           false,
	stateUser:           {},
	className:           '',
	stateDeepLink:       {},
	stateCurrentUserGeo: {},
	createDeepLink:      () => null,
	scrollIntoView:      () => null,
	isFetching:          false,
}
