import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import { PageWrapper } from 'components/PageWrapper'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useRef,
	useMemo,
} from 'react'
import {
	useHistory,
	useLocation,
	useParams,
} from 'react-router-dom'
import { GeoService } from 'services/GeoService'
import { queryParseVkLogin } from 'helpers/queryParseVkLogin'
import { useDeviceDetect } from 'helpers/useDeviceDetect'
import { About } from 'components/global/About'
import CitySelector from '../../components/global/CitySelector/index.store'
import TopBar from '../../components/global/TopBar/index.store'
import MainItemBlock from '../../components/MainItemBlock/index.store'
import styles from './styles.module.scss'
import { mapDispatchToProps, mapStateToProps } from "./index.props"

const getRequestParams = requestParams => JSON.parse(`{"${decodeURI(requestParams)
	.replace('?', '')
	.replace(/"/g, '\\"')
	.replace(/&/g, '","')
	.replace(/=/g, '":"')}"}`)

const searchQueryGetCities = DataProvider.buildQuery()
	.setQueryParam('by_ip', 1)
	.cacheResponse(3600, true)

export const Home = ({
	setReferrer,
	setSource,
	onLogin,
	geoState,
	setCurrentUserGeo,
	getItems,
	setQueryLocation,
	setQueryCategory,
	setQueryFilter,
	setQueryCity,
	categories,
	queryParams,
	getCity,
	getCities,
}) => {
	const { isNotDesktop, width } = useDeviceDetect()
	const widthTopBar = useRef()
	const widthCounter = useRef()
	const isGeo = geoState?.result ?? false
	const history = useHistory()
	const params = useParams()

	const { location } = useMemo(() => queryParams, [queryParams])
	const { city: urlCity, category: urlCategory, filter: urlFilter } = useMemo(() => params, [params])

	const { location: { search = [] } = {} } = useLocation()

	useEffect(() => {
		const fetch = async () => {
			const { names, lat, lon } = await GeoService.getInstance().search(urlCity)
			setQueryCity({ name: names.default, alias: names.url })
			setQueryLocation({ lat, lon })
		}

		if(urlCity) {
			fetch()
		}
		else {
			const searchQuery = DataProvider.buildQuery()
				.setQueryParam('lat', location?.lat)
				.setQueryParam('lon', location?.lon)
				.setSuccessCallback(({ result: { name, alias} }) => {
					setQueryCity({ name, alias })
				})
			getCity(searchQuery)
		}

	}, [urlCity, setQueryCity, setQueryLocation, setQueryCity])

	useEffect(() => {
		let category = { id: 0 }
		let filter = { id: 0 }

		if(urlCategory){
			category = urlCategory ?
				categories.select.find(
					i => i.alias_en === urlCategory || i.alias_ru === urlCategory) :
				categories.select[0]
		}
		if(urlFilter){
			filter = urlFilter ?
				categories.filtered.find(
					i => i.alias_en === urlFilter || i.alias_ru === urlFilter) :
				categories.filtered[0]
		}
		const searchQuery = DataProvider.buildQuery()
			.setQueryParam('filter', filter?.id || 0)
			.setQueryParam('category', category?.id || 0)
			.setQueryParam('sort', queryParams.sorting || 0)
			.setQueryParam('distance', queryParams.distance || 0)


		const { lat, lon } = location
		if(urlCity){
			searchQuery.setQueryParam('city', urlCity)
		}
		else {
			searchQuery.setQueryParam('lat', lat)
			searchQuery.setQueryParam('lon', lon)
		}

		setQueryCategory(category.id)
		setQueryFilter(filter.id)
		getItems(searchQuery)

	}, [urlCity, urlFilter, urlCategory, queryParams.sorting, queryParams.distance, location])

	useEffect(() => {
		if (isGeo && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				const pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				}
				setCurrentUserGeo(pos)
			})
		}

		getCities(searchQueryGetCities)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGeo])

	const widthRef = () => {
		const resultWidth = widthTopBar?.current?.getBoundingClientRect().width -
			widthCounter?.current?.getBoundingClientRect().width
		if (width.width < 1300 && width.width > 1195.5) {
			return `${resultWidth - 32}px`
		}
		if (width.width < 1195.5 && width.width > 839.5) {
			return `${resultWidth}px`
		}
		if (width.width < 839.5) {
			return `${widthTopBar?.current?.getBoundingClientRect().width}px`
		}
		return `${resultWidth - 52}px`
	}

	if (search.length > 0) {
		try {
			const { lot_id, referrer, source } = getRequestParams(search) ?? {}
			if (referrer) setReferrer(referrer)
			if (source) setSource(source)
			// if (lot_id) history.push(`/lot/${lot_id}`)
		} catch (e) {
			// history.push('/')
		}
	}

	useEffect(() => {
		queryParseVkLogin(history, onLogin, error => console.log('Error vk Login', error) )
	}, [history, onLogin])

	return (
		<PageWrapper>
			<div className={styles.wrapper}>
				<TopBar widthTopBar={widthTopBar} widthCounter={widthCounter} />
				<MainItemBlock
					isFetching={false}
					width={widthRef()}
				/>
				{!isNotDesktop && <CitySelector />}
				{!isNotDesktop && <About />}
			</div>
		</PageWrapper>
	)
}

Home.getInitialProps = SSRComponentHelper.initialProps(async (dispatchers, { match, store, isSSR }) => {
	if (isSSR) {
		const { getItems, getCities, getCategories } = dispatchers
		// const { setQueryParams } = mapDispatchToProps
		const { params: { city, category, filter } } = match

		const searchQuery = DataProvider.buildQuery()
			.setQueryParam('distance', 10)
			.setQueryParam('sort', 9)
			.setQueryParam('city', city || 'minsk')
			.cacheResponse(60 * 60, true)


		const searchQueruCategories = DataProvider.buildQuery()
			.cacheResponse(60 * 60, true)

		try {
			// await store?.dispatch?.(setQueryParams(queryParams))

			await Promise.all([
				getCategories(searchQueruCategories),
				getItems(searchQuery),
				getCities(searchQueryGetCities)
			])
		}
		catch (e){
			console.info('ErrorHelper', e)
		}
	}
}, mapDispatchToProps)

Home.propTypes = {
	onLogin:           PropTypes.func,
	setReferrer:       PropTypes.func,
	setSource:         PropTypes.func,
	setCurrentUserGeo: PropTypes.func,
	geoState:          PropTypes.object,
	getItems:          PropTypes.func,
	setQueryLocation:  PropTypes.func,
	setQueryCategory:  PropTypes.func,
	setQueryFilter:    PropTypes.func,
	setQueryCity:      PropTypes.func,
	categories:        PropTypes.object,
	queryParams:       PropTypes.object,
	getCity:           PropTypes.func,
	getCities:         PropTypes.func,
}
Home.defaultProps = {
	setReferrer:       () => null,
	setSource:         () => null,
	onLogin:           () => null,
	setCurrentUserGeo: () => null,
	geoState:          {},
	getItems:          () => null,
	setQueryLocation:  () => null,
	setQueryCategory:  () => null,
	setQueryFilter:    () => null,
	setQueryCity:      () => null,
	categories:        {},
	queryParams:       {},
	getCity:           () => null,
	getCities:         () => null,
}
