import { REDIRECT_URL } from 'reduxm/redux-saga/config'

// eslint-disable-next-line consistent-return
export const queryParseVkLogin = (history, successCallback, errorCallback = () => null) => {
	const query = history.location.search
	const urlParams = new URLSearchParams(query)
	const keys = urlParams.keys()

	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys) {
		if (key === 'error') errorCallback({error: new URLSearchParams(query).get('error')})
		if (key === 'code') {
			const codeURL = new URLSearchParams(query).get('code')

			successCallback({
				redirect_uri:  REDIRECT_URL,
				identity:      codeURL,
				code:          codeURL,
				service:       'vkontakte',
			})
			history.push('/')
		}
	}
}
