import PropsTypes from 'prop-types'
import React, { useMemo } from 'react'
import positionSmallIcon from '../../../assets/images/positionSmallIcon.svg'
import { useDeviceDetect } from '../../../helpers/useDeviceDetect'
import styles from './styles.module.scss'

export const Marker = ({ distance, zoom }) => {
	const { isLessIPadMini, width, height } = useDeviceDetect()

	const r = (zoom - 9) * 100
	const z = zoom > 10 ? (zoom - 9) * 180 : (zoom - 9) * 120

	const zm = () => {
		if (zoom <= 9 && zoom > 7) {
			return (zoom - 9) * 50
		}
		if (zoom <= 7 && zoom > 6) {
			return (zoom - 9) * 38
		}
		if (zoom <= 6 && zoom > 5) {
			return (zoom - 9) * 29
		}
		if (zoom <= 5 && zoom > 4) {
			return (zoom - 9) * 22.5
		}
		return -100
	}

	const radius = r >= 0 ? (distance * 10) + z : (distance * 10) + (zm())

	const left = useMemo(() => {
		if (isLessIPadMini) {
			return { value: width.width / 2 - radius / 2, param: 'px' }
		}
		if (radius > 50) {
			// eslint-disable-next-line no-shadow
			const r = radius - 50

			return { value: 45.8 - (r * 0.086), param: '%' }
		}
		if (radius < 50) {
			// eslint-disable-next-line no-shadow
			const r = 50 - radius

			if (radius < 4) {
				return { value: 49.8, param: '%' }
			}

			return { value: 45.8 + (r * 0.08), param: '%' }
		}

		return { value: 45.8, param: '%' }
	}, [isLessIPadMini, radius, width.width, zoom])

	const top = useMemo(() => {
		if (isLessIPadMini) {
			if (radius > 50) {
				const t = (radius - 50) * 0.5

				return { value: height.height / 2 - radius + t, param: 'px' }
			}
			if (radius < 50) {
				const t = (50 - radius) * 0.5

				return { value: height.height / 2 - radius - t, param: 'px' }
			}
			return { value: height.height / 2 - radius, param: 'px' }
		}
		if (radius > 50) {
			// eslint-disable-next-line no-shadow
			const r = radius - 50

			return { value: 41 - (r * 0.16), param: '%' }
		}
		if (radius < 50) {
			// eslint-disable-next-line no-shadow
			const r = 50 - radius

			if (radius < 4) {
				return { value: 46.8, param: '%' }
			}

			return { value: 41 + (r * 0.122), param: '%' }
		}

		return { value: 41, param: '%' }
	}, [radius, height.height, isLessIPadMini, zoom])

	const leftImg = useMemo(() => (
		isLessIPadMini ? width.width / 2 - 17.5 : 592 / 2 - 17.5),
	[width.width, isLessIPadMini])

	const topImg = useMemo(() => (
		isLessIPadMini ? height.height / 2 - 72 : 300 / 2 - 48),
	[isLessIPadMini, height.height])

	return (
		<>
			<div
				className={styles.circle}
				style={{
					width:  `${radius}px`,
					height: `${radius}px`,
					left:   `${left.value}${left.param}`,
					top:    `${top.value}${top.param}`,
				}}
			/>
			<div
				className={styles.markerImg}
				 style={{ left: `${leftImg}px`,	top: `${topImg}px` }}
			>
				<img
					src={positionSmallIcon}
					className={styles.iconPositionImg}
					alt="icon"
				/>
			</div>
		</>

	)
}

Marker.propTypes = {
	distance:    PropsTypes.number,
	zoom:        PropsTypes.number,
}
Marker.defaultProps = {
	distance:    10,
	zoom:        9,
}
