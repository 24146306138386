import { SvgIcon } from '@material-ui/core'
import { Adjust } from '@material-ui/icons'
import GoogleMapReact from 'google-map-react'
import PropsTypes from 'prop-types'
import React, {
	useEffect,
	useState,
	useCallback,
	useMemo,
} from 'react'
import currentPositionIcon from '../../../assets/images/myPosition.svg'
import { Marker } from './Marker'
import styles from './styles.module.scss'

const CurrentUserPosition = () => <SvgIcon
	component={Adjust}
	fontSize="small"
	viewBox="0 0 26 26"
	color="primary"
/>

export const GoogleMap = ({ positions, setLocation, distance, getItemsTotal, isGeo, intlState }) => {
	const [currentPosition, setCurrentPosition] = useState(null)
	const [zoom, setZoom] = useState(9)

	useEffect(() => {
		if (isGeo && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				const pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				}
				setCurrentPosition(pos)
			})
		}
	}, [isGeo])

	const onChange = useCallback(({ center }) => {
		const { lat, lng: lon} = center
		setLocation(center)
		getItemsTotal({ distance, lat, lon })
	}, [setLocation, getItemsTotal, distance])

	const defaultMapOptions = useMemo(() => ({
		fullscreenControl: false,
		zoomControl:       false,
		minZoom:           4,
	}), [])

	const setPosition = () => {
		const mapStyle = document.querySelector('.gm-style')
		setLocation(currentPosition)
		if (mapStyle) {
			mapStyle.style.marginTop = '-15px'
			mapStyle.style.marginLeft = '-8.5px'
		}
	}

	const handleZoom = useCallback(size => {
		setZoom(size)
	}, [])

	return (
		<div className={styles.wrapper}>
			 <Marker distance={distance} zoom={zoom} />
			 {isGeo && <div className={styles.currentPosition} onClick={setPosition} role="none">
				         <img src={currentPositionIcon} alt="я тут" />
				{/* eslint-disable-next-line */}
			 </div>}
			<GoogleMapReact
				bootstrapURLKeys={{
					key:       'AIzaSyDxWiZxY3Z9Ht8NwoOtV_a_wTCdvOQ2TUE',
					language:  intlState.locale,
					region:    intlState.locale,
					libraries: ['places'],
				}}
				center={positions}
				defaultZoom={9}
				yesIWantToUseGoogleMapApiInternals
				options={defaultMapOptions}
				onZoomAnimationStart={handleZoom}
				onChange={onChange}
			>
				{currentPosition && <CurrentUserPosition
					lat={currentPosition.lat}
					lng={currentPosition.lng}
					text="My Marker"
				/>}
			</GoogleMapReact>
		</div>
	)
}

GoogleMap.propTypes = {
	positions:     PropsTypes.object,
	intlState:     PropsTypes.object,
	isGeo:         PropsTypes.bool,
	distance:      PropsTypes.number,
	setLocation:   PropsTypes.func,
	getItemsTotal: PropsTypes.func,
}
GoogleMap.defaultProps = {
	setLocation:   () => null,
	isGeo:         false,
	getItemsTotal: () => null,
	positions:     {},
	intlState:     {},
	distance:      10,
}
