import { connect } from 'react-redux'
import {
	StateSelector,
} from 'reduxm/modules/selectors'
import { BannerBlock } from './index'

const mapStateToProps = state => ({
	geoState:               StateSelector.project.getGeoState(state),
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(BannerBlock)
