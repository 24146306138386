import { connect } from 'react-redux'
import { DispatchSelector, StateSelector } from 'reduxm/modules/selectors'
import { isFetching } from '../../helpers/methods'
import { setQueryParams } from '../../reduxm/redux-saga/modules/base/actionsCreators'

import { MainItemBlock } from './index'

const mapStateToProps = state => ({
	stateItems:       StateSelector.items.getItems(state),
	isFetching:       isFetching(
		StateSelector.items.getItems(state),
		StateSelector.items.getSearchItems(state),
	),
	stateSpacerItems: StateSelector.items.getSpacerItems(state),
	stateSliderItems: StateSelector.items.getSliderItems(state),
	searchString:     state.base.searchString,
	queryParams:      state.queryParams,
})
const mapDispatchToProps = {
	getSpacerItems:     DispatchSelector.items.getSpacerItems,
	getSliderItems:     DispatchSelector.items.getSliderItems,
	getMoreItems:       DispatchSelector.items.getMoreItems,
	getItems:           DispatchSelector.items.getItems,
	setQueryParams,
	getSearchItems:     DispatchSelector.items.getSearchItems,
	getMoreSearchItems: DispatchSelector.items.getMoreSearchItems,
}
export default connect(mapStateToProps, mapDispatchToProps)(MainItemBlock)
