import { connect } from 'react-redux'
import { isFetching } from '../../../helpers/methods'
import { DispatchSelector, StateSelector } from '../../../reduxm/modules/selectors'
import { scrollIntoView } from '../../../reduxm/redux-saga/modules/base/actionsCreators'
import { ItemCard } from './index'

const mapStateToProps = state => ({
	stateUser:           StateSelector.user.getUser(state),
	stateDeepLink:       StateSelector.user.createDeepLink(state),
	stateCurrentUserGeo: StateSelector.project.setCurrentUserGeo(state),
	isFetching:          isFetching(
		StateSelector.user.createDeepLink(state),
	),

})
const mapDispatchToProps = {
	createDeepLink: DispatchSelector.user.createDeepLink,
	scrollIntoView,
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemCard)
