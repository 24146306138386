import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { SelectCategory } from 'components/global/TopBar/SelectCategory'
import { SelectSorting } from 'components/global/TopBar/SelectSorting'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
} from 'react'
import { useIntl } from 'react-intl'
import { toQueryParams } from 'helpers/methods'
import {
	useParams,
} from 'react-router-dom'
import { Spinner } from '../Spinner'
import ChildrenCategories from './ChildrenCategories/index.store'
import { FilterButton } from './FilterButton'
import MapButton from './MapButton/index.store'
import styles from './styles.module.scss'

export const TopBar = (
	{
		setQueryParams,
		queryParams,
		widthTopBar,
		widthCounter,
		stateItemsCount,
		getItemsCount,
		categories,
		setQuerySorting,
	},
) => {
	const intl = useIntl()
	const params = useParams()

	const { urlCity, urlCategory, urlFilter } = useMemo(() => {
		const { city, category, filter } = params
		return { urlCity: city || queryParams.city.alias, urlCategory: category, urlFilter: filter }
	}, [params, queryParams.city])


	const { titleSelected, titleSorting, titleFiltered } = useMemo(() => {
		const selected = categories.select
			.find(i => i.id !== 0 && (i.alias_en === urlCategory || i.alias_ru === urlCategory))

		const filtered = categories.filtered
			.find(i => i.id !== 0 && (i.alias_en === urlFilter || i.alias_ru === urlFilter))

		const sorting = categories.sorting.find(i => i.id === queryParams.sorting)

		return {
			titleSelected: selected || { id: 0, title: intl.formatMessage({ id: 'filter_categories' }) },
			titleSorting: sorting || { id: 0, title: intl.formatMessage({ id: 'filter_sorting' }) },
			titleFiltered: filtered || { id: 0, title: intl.formatMessage({ id: 'filter_type'}) },
		}
	}, [intl, urlCategory, urlFilter, queryParams.sorting])

	const [childrenCategories, setChildrenCategories] = useState([])
	const [filterChildren, setFilterChildren] = useState({})

	const cityName = useMemo(() =>
		queryParams?.city?.name || 'City', [queryParams])

	const onSelectSort = useCallback((sort, sortItem) => {
		setQuerySorting(parseInt(sort, 10))
	},
	[setQuerySorting])

	useEffect(() => {
		if (titleSelected.id !== 'category') {
			const sortedId = titleSorting.id === 'sort' ? 9 : titleSorting.id
			const searchQuery = DataProvider.buildQuery()
			toQueryParams(searchQuery, {
				distance:    queryParams.distance,
				category_id: sortedId,
				filter:      titleSelected.id,
				lat:         queryParams.location.lat,
				lon:         queryParams.location.lon,
			})
			getItemsCount(searchQuery)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [titleSelected, titleSorting, queryParams.distance, queryParams.location])

	const closeFilter = () => {
		setFilterChildren({})
		setQueryParams({
			...queryParams,
			filter: titleSelected.id,
		})
	}

	const openFilter = elem => {
		setFilterChildren(elem)
	}

	return (
		<>
			{titleSelected.id !== 0 &&
			<div className={styles.header}>
				<h1>
					<span
						className={styles.title}
					>
						{`${titleSelected.title} - ${cityName}`}
					</span>

					<span className={styles.count}>
						{stateItemsCount?.fetching ?
							<Spinner isFetching size={16} /> : stateItemsCount?.result?.count}
					</span>
				</h1>
				<div className={styles.description}>
					{intl.formatMessage({ id: 'category_info' })}
				</div>
				{childrenCategories.length && !filterChildren.title ?
					<ChildrenCategories childrenCategories={childrenCategories} openFilter={openFilter} /> :
					null}
			</div>}
			<div className={[styles.bottomGroup, styles.bottomGroupActive].join(' ')} ref={widthTopBar}>
				<div className={styles.buttons}>
					<SelectCategory city={urlCity} filterArray={categories.select} filter={titleSelected} />
					<SelectSorting sortingArray={categories.sorting} onChange={onSelectSort} sorting={titleSorting} />
					<FilterButton city={urlCity} filterArray={categories.filtered} filter={titleFiltered} />
				</div>
				<div className={styles.buttons}>
					<MapButton />
					<div className={styles.counter} ref={widthCounter} />
				</div>
			</div>
		</>
	)
}

TopBar.propTypes = {
	setQueryParams:  PropTypes.func,
	getItemsCount:   PropTypes.func,
	queryParams:     PropTypes.object,
	widthTopBar:     PropTypes.object,
	widthCounter:    PropTypes.object,
	stateItemsCount: PropTypes.object,
	categories:      PropTypes.object,
	setQuerySorting: PropTypes.func,
}
TopBar.defaultProps = {
	setQueryParams:  () => null,
	getItemsCount:   () => null,
	queryParams:     {},
	widthTopBar:     {},
	widthCounter:    {},
	stateItemsCount: {},
	categories:      {},
	setQuerySorting: () => null,
}
