import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useRef,
	useState,
	useMemo,
} from 'react'
import { toQueryParams } from 'helpers/methods'
import { useDeviceDetect } from 'helpers/useDeviceDetect'
import mobilePhone from '../../assets/images/mobilePhone.png'
import BannerBlock from '../global/bannerBlock/index.store'
import { ItemBlock } from '../global/ItemBlock'
import { Spinner } from '../global/Spinner'
import styles from './styles.module.scss'


export const MainItemBlock = ({
	stateItems,
	getItems,
	getMoreItems,
	searchString,
	getSearchItems,
	getMoreSearchItems,
	queryParams,
}) => {
	const [isFetching, setIsFetching] = useState(false)
	const [page, setPage] = useState(2)
	const [isCloseGeo, setIsCloseGeo] = useState(true)
	const { isMobile } = useDeviceDetect()
	const wrapperWidth = useRef()

	const items = useMemo(() => stateItems?.result?.list || [], [stateItems])

	const getItemRequest = debounce(id => {
		const { category, filter, sorting, location: { lat, lon} } = queryParams
		const searchQuery = DataProvider.buildQuery()
			.setQueryParam('filter', filter)
			.setQueryParam('category', category)
			.setQueryParam('lat', lat)
			.setQueryParam('lon', lon)
			.setQueryParam('p', 1)
			.setQueryParam('sort', sorting)

		if(id) {
			searchQuery
				.setQueryParam('id', id)
				.setQueryParam('p', page)
			getMoreItems(searchQuery)
		}
		else if(!stateItems?.result?.list){
			getItems(searchQuery)
		}
	}, 500)

	const getSearchRequest = debounce(id => {
		const searchQuery = DataProvider.buildQuery()
		const queryLocation = id ? {
			id,
			q: searchString,
			p: page,
		} : {
			id: 0,
			q:  searchString,
			p:  1,
		}
		toQueryParams(searchQuery, queryLocation)

		return id ? getMoreSearchItems(searchQuery) : getSearchItems(searchQuery)
	}, 500)

	useEffect(() => {
		if (searchString.length > 1) {
			const searchQuery = DataProvider.buildQuery()
			const queryLocation = { q: searchString }
			toQueryParams(searchQuery, queryLocation)
			getSearchRequest()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchString])

	const onClickGetMore = () => {
		// оставить нужный
		// const id = items[items.length - 1]?.data.id
		const id = items[items.length - 1]?.id
		setPage(page + 1)
		return searchString.length ? getSearchRequest(id) : getItemRequest(id)
	}

	return (
		<>
			{
				isFetching ?
					<Spinner isFetching className={styles.spinner} /> :
					<>
						<div className={styles.container}>
							<div className={styles.wrapper} ref={wrapperWidth}>
								<ItemBlock
									items={items}
									isFetching={isFetching}
									onClickGetMore={onClickGetMore}
									setIsCloseGeo={setIsCloseGeo}
								/>
							</div>
							{!isMobile &&
							<div className={styles.bannerVerticalBlock}>
								<BannerBlock banner={mobilePhone} isCloseGeo={isCloseGeo} />
							</div>}
						</div>
						{/* <BannerHorizontal /> */}
						{/* <div className={styles.container}> */}
						{/*	<div className={styles.wrapper} > */}
						{/*		<div style={{ height: '28px' }} /> */}
						{/*		<ItemBlock */}
						{/*			onClickGetMore={onClickGetMore} */}
						{/*			items={items.slice(6)} */}
						{/*			isFetching={isFetching} */}
						{/*		/> */}
						{/*	</div> */}
						{/*	{!isMobile && */}
						{/*	<div className={styles.bannerVerticalBlock}> */}
						{/*		/!* <BannerBlock banner={adRightImage} width="160px" maxHeight="auto" /> *!/ */}
						{/*	</div>} */}
						{/* </div> */}
					</>
			}
		</>
	)
}

MainItemBlock.propTypes = {
	stateItems:         PropTypes.object,
	getItems:           PropTypes.func,
	getMoreItems:       PropTypes.func,
	queryParams:        PropTypes.object,
	searchString:       PropTypes.string,
	getSearchItems:     PropTypes.func,
	getMoreSearchItems: PropTypes.func,
}
MainItemBlock.defaultProps = {
	stateItems:         {},
	getItems:           () => null,
	getMoreItems:       () => null,
	queryParams:        {},
	searchString:       '',
	getSearchItems:     () => null,
	getMoreSearchItems: () => null,
}
