import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { setQueryParams } from '../../../reduxm/redux-saga/modules/base/actionsCreators'

import { CitySelector } from './index'

const mapStateToProps = state => ({
	stateCities: StateSelector.cities.getCities(state),
	userState:   StateSelector.user.getUser(state),
	queryParams: state.base.queryParams,
})

const mapDispatchToProps = {
	getUserUpDate:          DispatchSelector.user.getUserUpDate,
	setQueryParams,
}
export default connect(mapStateToProps, mapDispatchToProps)(CitySelector)
