import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import contactLess from '../../../assets/images/contactless.svg'
import contactLessImg from '../../../assets/images/contactLessImg.svg'
import Icon from '../Icon'
import styles from './styles.module.scss'

export const ContactLessDialog = ({ scrollIntoView }) => {
	const [isOpenDialog, setIsOpenDialog] = useState(false)
	const intl = useIntl()
	const setScroll = () => {
		scrollIntoView(true)
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.contactLess} onClick={() => setIsOpenDialog(true)} role="none">
				<img className={styles.contactLessImg} src={contactLess} alt="бесконтакта" />
			</div>
			<Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
				<div className={styles.dialogWrapper}>
					<div className={styles.iconsBlock}>
						<button type="button" onClick={() => setIsOpenDialog(false)}>
							<Icon icon="closeIcon" color="rgba(43, 43, 43, 0.6)" />
						</button>
						<div className={styles.image}>
							<img src={contactLessImg} alt="info" />
						</div>
					</div>
					<div className={styles.dialogTittle}>
						<h2>{intl.formatMessage({ id: 'safe_info_title' })}</h2>
					</div>
					<div className={styles.dialogText}>
						<span>
							{intl.formatMessage({ id: 'safe_info_message' })}
						</span>
						<Link
							to="/safely"
							onClick={setScroll}
							className={styles.link}
						>
							{intl.formatMessage({ id: 'how_it_work' })}
						</Link>
					</div>
				</div>
			</Dialog>
		</div>

	)
}

ContactLessDialog.propTypes = {
	scrollIntoView: PropTypes.func,
}
ContactLessDialog.defaultProps = {
	scrollIntoView: () => null,
}
