import { id_categories } from 'constants'

export class CategoriesFiltered {

	static isCategory = i => i.hidden && i.is_selectable && !i.is_filterable && !i.sorting

	static isSorting = i => i.sorting && !i.is_selectable && !i.is_filterable &&
		i.id !== id_categories.STORIES &&
		i.id !== id_categories.GIVE_AWAY || i.id === id_categories.FOR_YOU

	static isFilter = i => !i.hidden && !i.sorting && !i.is_selectable && i.is_filterable &&
		i.id !== id_categories.STORIES && i.id !== id_categories.THINGS_GIVEN


	static formatMessage = id => this.messages?.[this.locale]?.[id] || id

	static wrapped = ({ result: inputCategories }, { messages, locale }) => {
		this.messages = messages
		this.locale = locale

		const categories = {
			select: [{
				id: 0,
				title: this.formatMessage('tab_all'),
				children_categories: [],
				alias_en: 'all',
				alias_ru: 'vse',
			}],
			sorting: [],
			filtered: [],
		}

		inputCategories?.map?.(category => {
			if (category.is_selectable) categories.select.push(category)
			if (this.isSorting(category)) categories.sorting.push(category)
			if (this.isFilter(category)) categories.filtered.push(category)
		})

		return categories
	}
}