import { CategoriesFiltered } from 'helpers/CategoriesFiltered'
import { DispatchSelector, StateSelector } from 'reduxm/modules/selectors'
import { HierarchicalService } from 'services/HierarchicalService'
import {isFetching} from 'helpers/methods'
import {setQueryParams} from 'reduxm/redux-saga/modules/base/actionsCreators'
import {
	setQueryLocation,
	setQueryCity,
	setQueryCategory,
	setQueryFilter,
	setQuerySorting,
} from 'reduxm/redux-saga/modules/queryParams/actionsCreators'

const mapStateToProps = state => ({
	isFetching:        isFetching(
		StateSelector.items.getItems(state),
		StateSelector.items.getSearchItems(state),
	),
	stateCity:         StateSelector.cities.getCity(state),
	queryParams:       state.queryParams,
	userState:         StateSelector.user.getUser(state),
	geoState:          StateSelector.project.getGeoState(state),
	categories:        CategoriesFiltered.wrapped(StateSelector.categories.getCategories(state), state.intl)
})
const mapDispatchToProps = {
	getItems:           DispatchSelector.items.getItems,
	getCategories:      DispatchSelector.categories.getCategories,
	getCity:            DispatchSelector.cities.getCity,
	getCities:          DispatchSelector.cities.getCities,
	setReferrer:        DispatchSelector.project.setReferrer,
	setSource:          DispatchSelector.project.setSource,
	onLogin:            DispatchSelector.auth.login,
	getItemsCount:      DispatchSelector.items.getItemsCount,
	setCurrentUserGeo:  DispatchSelector.project.setCurrentUserGeo,
	setQueryParams,
	setQueryLocation,
	setQueryCity,
	setQueryCategory,
	setQueryFilter,
	setQuerySorting,
}

export { mapDispatchToProps, mapStateToProps }
