export const timeToString = sec => {
	const decade = numb => (numb < 10 ? `0${numb}` : numb)
	const decadeHours = (numb, days) => {
		const r = days * 24
		const hours = numb > 24 ? numb - r : numb

		return (hours < 10 ? `0${hours}` : hours)
	}
	const h = Math.floor(sec / 3600)
	const m = Math.floor(sec / 60) - (h * 60)
	const s = sec % 60
	const day = h / 24
	const countDay = day < 1 ? '' : `${Math.floor(day)}`

	return `${countDay} ${decadeHours(h, countDay)}\u200A:\u200A${decade(m)}\u200A:\u200A${decade(s)}`
}
