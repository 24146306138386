import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { setQueryParams } from '../../../reduxm/redux-saga/modules/base/actionsCreators'
import { GeoSolve } from './index'

const mapStateToProps = state => ({
	geoState:               StateSelector.project.getGeoState(state),
	userState:              StateSelector.user.getUser(state),
	queryParams:            state.base.queryParams,
	distance:               state.base.distance,
})
const mapDispatchToProps = {
	setGeoState:            DispatchSelector.project.setGeoState,
	setQueryParams,
	getUserUpDate:          DispatchSelector.user.getUserUpDate,
}
export default connect(mapStateToProps, mapDispatchToProps)(GeoSolve)
