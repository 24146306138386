import { QUERY_PARAMS_ACTIONS } from './actionTypes'

export const setQueryLocation = payload => ({
	type: QUERY_PARAMS_ACTIONS.SET_QUERY_LOCATION,
	payload,
})

export const setQueryCategory = payload => ({
	type: QUERY_PARAMS_ACTIONS.SET_QUERY_CATEGORY,
	payload,
})

export const setQueryFilter = payload => ({
	type: QUERY_PARAMS_ACTIONS.SET_QUERY_FILTER,
	payload,
})

export const setQuerySorting = payload => ({
	type: QUERY_PARAMS_ACTIONS.SET_QUERY_SORTING,
	payload,
})

export const setQueryDistance = payload => ({
	type: QUERY_PARAMS_ACTIONS.SET_QUERY_DISTANCE,
	payload,
})

export const setQueryCity = payload => ({
	type: QUERY_PARAMS_ACTIONS.SET_QUERY_CITY,
	payload,
})
