import PropTypes from 'prop-types'
import React, {
	useMemo,
} from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

export const CitySelector = ({ stateCities }) => {
	const intl = useIntl()
	const cities = useMemo(() => stateCities?.result?.list || [], [stateCities])

	return (
		<div className={styles.wrapper}>
			<div className={styles.tittle}>{intl.formatMessage({ id: 'select_city' })}</div>
			<div className={styles.buttonsGroup}>
				{cities.map(city => (
					<Link
						to={`/${city?.title_en}`}
						key={city.title_en}
					>
						{intl.locale === 'en' ? city.title_en : city.title_ru}
					</Link>
				)
				)}
			</div>
		</div>
	)
}

CitySelector.propTypes = {
	stateCities:    PropTypes.object,
}

CitySelector.defaultProps = {
	stateCities:           {},
}
