import { Popover } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import {
	Link,
	useParams,
} from 'react-router-dom'
import Icon from '../../Icon'
import styles from './styles.module.scss'

const anchorOrigin = {
	vertical:   'top',
	horizontal: 'left',
}
const transformOrigin = {
	vertical:   'top',
	horizontal: 'left',
}

export const FilterButton = React.memo(({ filterArray, onChange, filter, city }) => {
	const [anchorEl, setAnchorEl] = useState(false)

	const { category = 'all' } = useParams()
	const intl = useIntl()

	const handleClick = e => setAnchorEl(e.currentTarget)

	const handleClose = () => setAnchorEl(null)

	const onSelect = (e, el) => {
		const { id } = e.target
		setAnchorEl(null)
		onChange(id, el)
	}

	return (
		<>
			{
				filterArray[0] && (
					<>
						<button type="button" onClick={handleClick} className={styles.button}>
							<div>
								<p>
									{filter.title}
								</p>
								<Icon
									icon="arrowDownIcon"
									size={'32px'}
									color={'rgba(43, 43, 43, 0.36)'}
								/>
							</div>
						</button>
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={anchorOrigin}
							transformOrigin={transformOrigin}
						>
							<div className={styles.popoverWrapper}>
								{filterArray.map(el => {
									const url = `/${city}/${category}/${intl.locale === 'en' ? el.alias_en : el.alias_ru}`

									return (
										<Link
											to={url}
											key={el.id}
											id={el.id}
											onClick={e => onSelect(e, el)}
										>
											{el.title}
											{filter.title === el.title ?
												<Icon icon="filterSelectIcon" size="16px" /> : null}
										</Link>
									)
								})
								}
							</div>
						</Popover>
					</>
				)
			}
		</>
	)
})

FilterButton.propTypes = {
	filterArray: PropTypes.array,
	filter:      PropTypes.object,
	onChange:    PropTypes.func,
	city:        PropTypes.string,
}
FilterButton.defaultProps = {
	filterArray: [],
	filter:      {},
	onChange:    () => null,
	city:        '',
}
