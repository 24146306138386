import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { User } from '../../../../models'
import LoginForm from '../../LoginForm/index.store'
import { likeIcon } from './svgIcon'
import styles from './styles.module.scss'

export const LikeButton = ({ liked, itemId, className, likeItem, userState, promo }) => {
	const [isLiked, setIsLiked] = useState(false)
	const [isActiveLoginForm, setIsActiveLoginForm] = useState(false)

	useEffect(() => { setIsLiked(liked) }, [liked])

	const onClickLike = useCallback(() => {
		if (User.create(userState).isExist()) {
			const searchQuery = {
				lot_id: itemId,
				value:  !isLiked,
			}
			likeItem(searchQuery)
			setIsLiked(!isLiked)
		} else {
			setIsLiked(!isLiked)
			setTimeout(() => setIsActiveLoginForm(true), 100)
		}
	}, [isLiked, itemId, likeItem, userState])

	useEffect(() => {
		if (User.create(userState).isExist()) {
			setIsActiveLoginForm(false)
		}
	}, [userState])

	const onCloseLoginForm = useCallback(() => setIsActiveLoginForm(false), [])

	const getColor = () => {
		if (isLiked) {
			if (promo) {
				return '#FFC120'
			}
			return '#FE3F55'
		}
		return 'none'
	}

	return (
		<>
			<button type="button" onClick={onClickLike} className={`${styles.likeButton} ${className}`}>
				<svg
					width="19"
					height="17"
					viewBox="0 0 19 17"
					fill={getColor()}
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d={likeIcon.d}
						stroke={isLiked ? getColor() : likeIcon.stroke}
						strokeOpacity={likeIcon.strokeOpacity}
						strokeWidth={likeIcon.strokeWidth}
					/>
				</svg>
			</button>
			<LoginForm isActive={isActiveLoginForm} onClose={onCloseLoginForm} />
		</>
	)
}
LikeButton.propTypes = {
	liked:     PropTypes.bool,
	promo:     PropTypes.object,
	itemId:    PropTypes.number.isRequired,
	className: PropTypes.string,
	likeItem:  PropTypes.func,
	userState: PropTypes.object,
}
LikeButton.defaultProps = {
	liked:     false,
	promo:     null,
	className: '',
	likeItem:  () => null,
	userState: {},
}
