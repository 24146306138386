import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import styles from './styles.module.scss'

export const TopBarSpacerBlock = ({ title, titleLink, queryParams, setQueryParams, sort }) => {
	const showAll = () => {
		setQueryParams({
			...queryParams,
			filter: 0,
			sort,
		})
	}

	return (

		<div className={styles.spacer}>
			<span>{title}</span>
			<Link to="/" className={styles.seeAll} onClick={showAll} role="none">
				<span>{titleLink}</span>
				<Icon icon="breadcrumbsArrow" size="24px" color="rgba(43, 43, 43, 0.36)" />
			</Link>
		</div>

	)
}

TopBarSpacerBlock.propTypes = {
	title:          PropTypes.string,
	titleLink:      PropTypes.string,
	sort:           PropTypes.number,
	queryParams:    PropTypes.object,
	setQueryParams: PropTypes.func,
}
TopBarSpacerBlock.defaultProps = {
	title:          '',
	sort:           9,
	titleLink:      '',
	queryParams:    {},
	setQueryParams: () => null,
}
