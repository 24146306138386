export const likeIcon = {
	d: `M10.3098 15.9857L10.3075 15.9878C9.8543 16.4052 9.15835 16.405 8.70513 15.9816L8.70179 15.9785L8.59733 
	15.8821L8.5961 15.881C6.08882 13.5798 4.07542 11.7276 2.69507 9.9919C1.32516 8.26929 0.64916 6.74611 0.702989 
	5.1108C0.75281 3.68438 1.47494 2.3235 2.62335 1.53031L2.62373 1.53005C4.78798 0.0331232 7.48764 0.71159 8.96433 
	2.46494L9.49974 3.10066L10.0352 2.46494C11.5133 0.709844 14.2122 0.0252823 16.3743 1.52902L16.3761 1.53031C17.5247 
	2.32361 18.2469 3.68477 18.2965 5.11141L18.2965 5.11195C18.3547 6.74626 17.6794 8.26886 16.3079 9.99444C14.9294 
	11.7287 12.9201 13.5806 10.4208 15.8842L10.4047 15.899L10.4023 15.9012L10.3098 15.9857Z`,
	stroke:        '#2B2B2B',
	strokeOpacity: '0.8',
	strokeWidth:   '1.4',
}
