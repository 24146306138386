import { Popover } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {
	useState,
	useCallback,
} from 'react'
import {
	Link,
} from 'react-router-dom'
import Icon from '../../Icon'
import styles from './styles.module.scss'

const anchorOrigin = {
	vertical:   'top',
	horizontal: 'left',
}

const transformOrigin = {
	vertical:   'top',
	horizontal: 'left',
}

export const SelectSorting = React.memo(({ sortingArray, sorting, onChange }) => {
	const [anchorEl, setAnchorEl] = useState(false)

	const handleClick = useCallback(e => setAnchorEl(e.currentTarget),[])
	const handleClose = useCallback(() => setAnchorEl(null), [])

	const onSelect = useCallback((e, el) => {
		const { id } = e.target
		setAnchorEl(null)
		onChange(id, el)
	}, [onChange])

	return (
		<>
			{
				sortingArray[0] && (
					<>
						<button type="button" onClick={handleClick} className={styles.button}>
							<div>
								<p>
									{sorting.title}
								</p>
								<Icon
									icon="arrowDownIcon"
									size={'32px'}
									color={'rgba(43, 43, 43, 0.36)'}
								/>
							</div>
						</button>
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={anchorOrigin}
							transformOrigin={transformOrigin}
						>
							<div className={styles.popoverWrapper}>
								{sortingArray.map(el =>
									<Link
										to={'#'}
										key={el.id}
										id={el.id}
										onClick={e => onSelect(e, el)}
									>
										{el.title}
										{sorting.title === el.title ?
											<Icon icon="filterSelectIcon" size="16px" /> : null}
									</Link>)
								}
							</div>
						</Popover>
					</>
				)
			}
		</>
	)
})

SelectSorting.propTypes = {
	sortingArray: PropTypes.array,
	sorting:      PropTypes.object,
	onChange:    PropTypes.func,
}
SelectSorting.defaultProps = {
	sortingArray: [],
	sorting:      {},
	onChange:    () => null,
}
