import axios from 'axios'
import { googleCredentials } from 'constants'
import { array } from 'prop-types'

const result = (resolve, reject, response) => {
	const { status, data } = response
	if (status === 200 && (Array.isArray(data) && data.length))
	{

		const {
			lat,
			lon,
			namedetails,
			extratags: { place },
			address: { country },
			  } = Array.isArray(data) ? data[0] : data

		const isCountry = place !== 'city'

		const names = {
			isCountry,
			default: namedetails?.name,
			url: isCountry ? country?.toLowerCase?.() : namedetails?.['name:en']?.toLowerCase?.(),
			en: namedetails?.['name:en'] || namedetails?.name,
			ru: namedetails?.['name:ru'] || namedetails?.name,
		}
		resolve({ names, lat: parseFloat(lat), lon: parseFloat(lon) })
	}
	else reject(new Error(`something bad happened! status: ${status}`))
}

export class GeoService {
	/**
	 * @type {GeoService}
	 */
	static myInstance = null

	/**
	 * Get instance
	 *
	 * @return {GeoService}
	 */
	static getInstance() {
		if (this.myInstance === null) {
			this.myInstance = new GeoService()
		}

		return this.myInstance
	}

	reverse = async (lat, lon, lang = 'en') => {
		return new Promise(async (resolve, reject) => {
			const response = await axios.get(
				'https://nominatim.openstreetmap.org/reverse',
				{
					headers: {
						'Accept-Language': lang
					},
					params: {
						lat,
						lon,
						format:      'json',
						zoom:        10,
						namedetails: 1,
						extratags:   1,
					}
				}
			)

			result(resolve, reject, response)
		})
	}

	search = async (city, isCountry = false) => {
		return new Promise(async (resolve, reject) => {
			const response = await axios.get(
				'https://nominatim.openstreetmap.org/search',
				{
					headers: {
						'Accept-Language': 'en'
					},
					params: {
						...(isCountry ? { country: city } : { city }),
						addressdetails: 1,
						format:         'json',
						zoom:           10,
						namedetails:    1,
						extratags:      1,
					}
				}
			)

			result(resolve, reject, response)
		})
	}

	geocode = async (address, language = 'ru') => {
		return new Promise((resolve, reject) => {
			axios.get(
				'https://maps.googleapis.com/maps/api/geocode/json',
				{ params: {
					address,
					language,
					key: googleCredentials.GOOGLE_API_KEY,
				}},
			)
				.then(({ data: { status, results } }) => {
					if(status !== 'OK') {
						reject(status)
					}
					const { address_components, geometry: { location: { lat, lng: lon } } } = results?.[0]

					const city = address_components.filter(i => i.types.includes('locality'))
					// const country = address_components.filter(i => i.types.includes('country'))

					resolve({
						city,
						location: { lat, lon }
					})

				})
				.catch(e => {
					reject(e)
				})
		})
	}

	fromLatLon = async (latlng, language = 'ru') => {
		return new Promise((resolve, reject) => {
			axios.get(
				'https://maps.googleapis.com/maps/api/geocode/json',
				{ params: {
					latlng,
					language,
					key: googleCredentials.GOOGLE_API_KEY,
				}},
			)
				.then(({ data: { status, results } }) => {
					if(status !== 'OK') {
						reject(status)
					}
					const { address_components, geometry: { location: { lat, lng: lon } } } = results?.[0]

					const city = address_components.filter(i => i.types.includes('locality'))
					// const country = address_components.filter(i => i.types.includes('country'))

					resolve({
						city: city[0].short_name,
						location: { lat, lon }
					})

				})
				.catch(e => {
					reject(e)
				})
		})
	}
}
