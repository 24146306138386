import { getCookie } from '@kakadu-dev/base-frontend-helpers/helpers/Cookie'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { downloadLinks } from '../../Layout/Footer/data'
import { GrCode } from '../QrCode'
import styles from './styles.module.scss'

export const BannerBlock = ({
	banner,
	geoState,
	isCloseGeo,
}) => {
	const { result: isGeo } = geoState ?? false
	const intl = useIntl()
	const isTop = isGeo || getCookie('hidden_geo_solve') || !isCloseGeo ? '12px' : '0'
	return (
		<div
			className={styles.adBlock}
			style={{ top: isTop }}
		>
			{/* <Counter /> */}
			<div className={styles.qrCodeBlock}>
				<GrCode className={styles.qrCodeHome} />
			</div>

			<div className={styles.sticky}>
				<div className={styles.title}>
					<span>{intl.formatMessage({ id: 'download_app' }).split(' ')[0]}</span>
					<span>{intl.formatMessage({ id: 'give_away' })}</span>
					<span>{intl.formatMessage({ id: 'free' })}</span>
				</div>
				<div className={styles.downloadApp}>
					{downloadLinks.map(link => (
						<a href={link.href} key={link.id}>
							<img alt="download app" src={link.src} className={link.className} />
						</a>
					))}
				</div>
				<img className={styles.image} src={banner || ''} alt={banner ? 'download app' : ''} />
			</div>
		</div>
	)
}

BannerBlock.propTypes = {
	banner:     PropTypes.string,
	geoState:   PropTypes.object,
	isCloseGeo: PropTypes.bool,
}
BannerBlock.defaultProps = {
	banner:     '',
	geoState:   {},
	isCloseGeo: false,
}
